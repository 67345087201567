import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { css } from '@emotion/core'
import theme from '../../../config/theme'
import { rhythm } from '../../lib/typography'
import { bpMaxSM } from '../../lib/breakpoints'
import Message from '../ConfirmMessage/Message'
import { PleaseConfirmIllustration } from '../ConfirmMessage/Illustrations'

import addToMailchimp from 'gatsby-plugin-mailchimp'

const SubscribeSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  firstName: Yup.string(),
})

const PostSubmissionMessage = () => {
  return (
    <div>
      <Message
        illustration={PleaseConfirmIllustration}
        title={`Great, one last thing...`}
        body={`I just sent you an email with the confirmation link. 
          **Please check your inbox!**`}
      />
    </div>
  )
}

class SignUp extends React.Component {
  state = {
    submitted: false,
  }

  handleSubmit = async ({ email, firstName }, { setSubmitting }) => {
    this.setState({ submitted: true })
    const response = await addToMailchimp(email, {
      FNAME: firstName,
      URL: encodeURIComponent(window.location.href),
    })
    if (response.result === 'success') {
      this.setState({
        submitted: true,
        successful: true,
        errorMessage: null,
      })
      setSubmitting(false)
    } else {
      this.setState({
        submitted: false,
        errorMessage: response.msg,
      })
      setSubmitting(false)
    }
  }

  render() {
    const { submitted, successful, errorMessage } = this.state

    return (
      <div>
        {!successful && (
          <>
            <h2
              css={css`
                margin-bottom: ${rhythm(1)};
                margin-top: 0;
              `}
            >
              A whisper can change a life ― but only if you're listening.
            </h2>
            <p>
              Don't miss that whisper. Join my newsletter to hear about new
              posts.
            </p>
          </>
        )}

        <Formik
          initialValues={{
            email: '',
            firstName: '',
          }}
          validationSchema={SubscribeSchema}
          onSubmit={this.handleSubmit}
          render={({ errors, touched, isSubmitting }) => (
            <>
              {!successful && (
                <Form
                  css={css`
                    display: flex;
                    align-items: flex-end;
                    label:not(:first-of-type),
                    button {
                      margin-left: 10px;
                    }
                    .field-error {
                      display: block;
                      color: ${theme.colors.red};
                      font-size: 80%;
                    }
                    input,
                    label {
                      width: 100%;
                    }
                    ${bpMaxSM} {
                      flex-direction: column;
                      align-items: flex-start;
                      width: auto;
                      label,
                      input {
                        margin: 5px 0 0 0 !important;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                      }
                      button {
                        margin: 20px 0 0 0;
                      }
                    }
                  `}
                >
                  <label
                    htmlFor="firstName"
                    css={css`
                      flex: 2;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                      `}
                    >
                      First Name
                      <ErrorMessage
                        name="firstName"
                        component="span"
                        className="field-error"
                      />
                    </div>
                    <Field
                      aria-label="your first name"
                      aria-required="false"
                      name="firstName"
                      placeholder="Jane"
                      type="text"
                    />
                  </label>
                  <label
                    htmlFor="email"
                    css={css`
                      flex: 4;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                      `}
                    >
                      Email
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="field-error"
                      />
                    </div>
                    <Field
                      aria-label="your email address"
                      aria-required="true"
                      name="email"
                      placeholder="jane@acme.com"
                      type="email"
                    />
                  </label>
                  <button
                    data-element="submit"
                    type="submit"
                    disabled={isSubmitting}
                    css={css`
                      flex: 1;
                    `}
                  >
                    {!isSubmitting && 'Join now'}
                    {isSubmitting && 'Submitting...'}
                  </button>
                </Form>
              )}
              {submitted && !isSubmitting && <PostSubmissionMessage />}
              {errorMessage && <div>{errorMessage}</div>}
            </>
          )}
        />
      </div>
    )
  }
}

export default SignUp
