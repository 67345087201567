import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import theme from '../../config/theme'

import Container from './Container'

const Header = ({
  dark,
  bgColor = 'none',
  siteTitle,
  headerColor = 'black',
}) => (
  <header
    css={css`
      width: 100%;
      flex-shrink: 0;
      background: ${bgColor};
    `}
  >
    <div
      css={css`
        background: rgba(255, 255, 255, 0.15);
        padding: 15px 0 15px 0;
        border-bottom: 1px solid rgba(0,0,0,0.25);
      `}
    >
      <Container noVerticalPadding>
        <nav
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${headerColor};
            a {
              color: ${headerColor ? headerColor : theme.colors.body_color};
            }
            a:hover {
              color: ${headerColor === theme.colors.white
                ? 'white'
                : theme.colors.link_color_hover};
            }
          `}
        >
          <Link to="/" aria-label="go to homepage" activeClassName="active">
            Home
          </Link>
          <Link to="/blog" activeClassName="active" aria-label="view all posts">
            All Posts
          </Link>
        </nav>
      </Container>
    </div>
  </header>
)

export default Header

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
